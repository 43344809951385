// extracted by mini-css-extract-plugin
export var darkButton = "password-reset-module--darkButton--e3abb";
export var headingLarge3xl = "password-reset-module--headingLarge3xl--aabbf";
export var heroContent = "password-reset-module--heroContent--e819d";
export var heroResetsection = "password-reset-module--heroResetsection--75539";
export var heroText = "password-reset-module--heroText--74865";
export var herobackgroundIcon = "password-reset-module--herobackgroundIcon--c6c31";
export var herosection = "password-reset-module--herosection--0b065";
export var input = "password-reset-module--input--580e4";
export var inputs = "password-reset-module--inputs--09d51";
export var inputtextStyle1 = "password-reset-module--inputtextStyle1--36ecf";
export var label = "password-reset-module--label--5e7ba";
export var overlay = "password-reset-module--overlay--d4457";
export var passwordreset = "password-reset-module--passwordreset--a8432";
export var registerheretext = "password-reset-module--registerheretext--9d528";
export var sectionResetText = "password-reset-module--sectionResetText--0d29c";
export var sectionText = "password-reset-module--sectionText--c5893";
export var signinform = "password-reset-module--signinform--75b8b";
export var signinsection = "password-reset-module--signinsection--0eb80";
export var signinslot = "password-reset-module--signinslot--1ff17";
export var yourEmail = "password-reset-module--yourEmail--2c210";