import * as React from 'react';
import * as styles from '../../pages/password-reset.module.css';

import { Input, Text } from '@chakra-ui/react';
import { gql, useMutation } from '@apollo/client';

const SEND_PASSWORD_RESET_EMAIL = gql`
	mutation sendPasswordResetEmail($username: String!) {
		sendPasswordResetEmail(input: { username: $username }) {
			user {
				databaseId
			}
		}
	}
`;

const SendPasswordResetEmailForm: React.FC = () => {
	const [sendPasswordResetEmail, { loading, error, data }] = useMutation(
		SEND_PASSWORD_RESET_EMAIL
	);
	const [emailSent, setEmailSent] = React.useState(false);

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const data: any = new FormData(event.currentTarget);
		const { email } = Object.fromEntries(data);
		sendPasswordResetEmail({
			variables: {
				username: email,
			},
			onCompleted: () => {
				setEmailSent(true);
			},
		}).catch((error) => {
			console.error(error);
		});
	}

	if (emailSent) {
		return (
			<div className={styles.passwordeset}>
				<div className={styles.heroResetsection}>
					<img
						className={styles.herobackgroundIcon}
						alt=''
						src='/dm-signin-hero.webp'
					/>
					<div className={styles.overlay} />
					<div className={styles.heroContent}>
						<div className={styles.heroText}>
							<Text className={styles.sectionResetText}>
								Please check your email. A password reset link has been sent to
								you.
							</Text>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<form
			method='post'
			onSubmit={handleSubmit}>
			<div className={styles.passwordreset}>
				<div className={styles.herosection}>
					<img
						className={styles.herobackgroundIcon}
						alt=''
						src='/dm-signin-hero.webp'
					/>
					<div className={styles.overlay} />
					<div className={styles.heroContent}>
						<div className={styles.heroText}>
							<h1 className={styles.headingLarge3xl}>Password Reset</h1>
							<div className={styles.registerheretext}>
								<Text className={styles.sectionText}>
									Please enter your e-mail address, and we’ll send you the link
									to reset your password.
								</Text>
							</div>
						</div>

						<div className={styles.signinslot}>
							<div className={styles.signinsection}>
								<div className={styles.signinform}>
									<div className={styles.inputs}>
										<div className={styles.inputtextStyle1}>
											<Text className={styles.yourEmail}>Your email</Text>
											<fieldset
												disabled={loading}
												aria-busy={loading}></fieldset>
											<Input
												className={styles.input}
												textColor='#1a202c'
												borderColor='#1a202c'
												focusBorderColor='#dca672'
												_hover={{ border: '1px solid #dca672' }}
												id='email'
												type='email'
												name='email'
												py='1.5rem'
												autoComplete='email'
												placeholder='Enter your email'
												required
											/>
											{error ? (
												<Text
													py='2'
													color='red'
													className='error-message'>
													{error.message}{' '}
												</Text>
											) : (
												<Text></Text>
											)}
										</div>
									</div>
									<button
										type='submit'
										className={styles.darkButton}>
										<b className={styles.label}>
											{loading ? 'Sending...' : 'RESET PASSWORD'}
										</b>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default SendPasswordResetEmailForm;
