import { Box, Container, Heading, Image, } from "@chakra-ui/react"
import React from "react"

const Loading: React.FC = () => {

    return (
    <Box bg="bg-surface" py="28" position="relative" minH="100vh">
        <Container maxW="800px" px="4" py={{ base: '4', md: '8' }} >
          
            <Box display="flex" justifyContent="center" bg="white" py="14" px='2' textAlign="center" borderRadius="lg" >
                <Box w={{ base: '100%', md: "480px" }}>
                    <Image src="/dm-spinner.gif" alt="Loading GIF" />
                </Box>
            </Box>
        </Container>
    </Box>
    )
}

export default Loading